import React from 'react';
import { CaretRight } from 'phosphor-react';
import { Box } from '@superside/ui';
import type { SlidesNavigationProps } from '.';

export const SlidesNavigation: React.FC<SlidesNavigationProps> = ({
  currentIndex,
  totalSlides,
  onPrev,
  onNext,
  resetTransform
}) => {
  return (
    <>
      {currentIndex > 0 && (
        <Box
          className='1280:flex hover:bg-bor-background/[0.15] text-light absolute left-6 z-10 hidden h-12 w-12 cursor-pointer items-center justify-center rounded'
          onClick={(e) => onPrev(e, resetTransform)}
        >
          <CaretRight size={20} weight='bold' className='rotate-180' />
        </Box>
      )}
      {currentIndex < totalSlides - 1 && (
        <Box
          className='1280:flex hover:bg-bor-background/[0.15] text-light absolute right-6 z-10 hidden h-12 w-12 cursor-pointer items-center justify-center rounded'
          onClick={(e) => onNext(e, resetTransform)}
        >
          <CaretRight size={20} weight='bold' />
        </Box>
      )}
    </>
  );
};
