import React, { useEffect } from 'react';
import { X } from 'phosphor-react';
import { Box, cn } from '@superside/ui';
import type { SanityImage } from '@konsus/image';
import styles from './index.module.css';
import { ZoomInAndOut } from './ZoomInAndOut';

interface PreviewModalHeaderProps {
  title?: string;
  customTitle?: string;
  hashtags?: string[];
  closeButtonHandler: () => void;
  image?: SanityImage;
  isVisualAssetImage: boolean;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  resetTransform: () => void;
  nextSlide: () => void;
  prevSlide: () => void;
}

export const PreviewModalHeader: React.FC<PreviewModalHeaderProps> = (props) => {
  const {
    title,
    customTitle,
    hashtags,
    closeButtonHandler,
    image,
    isVisualAssetImage,
    zoom,
    setZoom,
    resetTransform,
    nextSlide,
    prevSlide
  } = props;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        resetTransform();
        setZoom(100);
        nextSlide();
      } else if (event.key === 'ArrowLeft') {
        resetTransform();
        setZoom(100);
        prevSlide();
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        closeButtonHandler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextSlide, prevSlide, resetTransform, setZoom]);

  return (
    <>
      <Box
        className='1024:block absolute z-[2] hidden h-full w-full cursor-pointer'
        onClick={closeButtonHandler}
      />
      <Box className='<1024:top-[5%] absolute top-[2%] z-[99]'>
        {image || isVisualAssetImage ? <ZoomInAndOut percentage={zoom} setZoom={setZoom} /> : null}
      </Box>
      <Box
        className={cn(
          styles.modalHeader,
          'absolute left-0 top-0 z-[3] w-full flex-row justify-between p-6'
        )}
      >
        <Box className='1024:max-w-[calc(50%_-_160px)] max-w-[500px] shrink'>
          {title || customTitle ? (
            <span className='text-bor-background font-bold'>{customTitle || title}</span>
          ) : (
            <span />
          )}

          {hashtags ? (
            <Box className='flex-row gap-2'>
              {hashtags.map((tag) => (
                <span className='text-bor-background' key={tag}>
                  #{tag}
                </span>
              ))}
            </Box>
          ) : null}
        </Box>

        <button
          className='1024:flex 1024:h-12 1024:hover:!bg-light/10 1024:items-center 1024:justify-center 1024:rounded 1024:w-12'
          onClick={closeButtonHandler}
        >
          <X size={24} color='#FAFAFA' />
        </button>
      </Box>
    </>
  );
};
