import React, { useCallback } from 'react';
import Image from 'next/image';
import { Play } from 'phosphor-react';
import { Box, cn } from '@superside/ui';
import NextImage, { type SanityImage } from '@konsus/image';
import type { SlideType, IVisualAsset } from '@konsus/lego/src/components/VisualAsset/types';

interface ThumbnailsProps {
  thumbnails: {
    slides: SlideType[];
    activeIndex: number;
  };
  setCurrentIndex: (index: number) => void;
  currentIndex: number;
  slides: SlideType[];
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  resetTransform: () => void;
}

export const Thumbnails: React.FC<ThumbnailsProps> = ({
  thumbnails,
  setCurrentIndex,
  currentIndex,
  slides,
  setZoom,
  resetTransform
}) => {
  const onClickHander = useCallback(
    (key: string) => {
      const newIndex = slides.findIndex((item) => item._key === key);

      if (currentIndex !== newIndex) {
        resetTransform();
        setZoom(100);
        setCurrentIndex(newIndex);
      }
    },
    [currentIndex, setCurrentIndex, slides, resetTransform, setZoom]
  );

  return (
    <Box className='1280:flex hidden flex-row items-center gap-2'>
      {thumbnails.slides?.map((item, i) => (
        <Box
          key={item._key}
          onClick={() => onClickHander(item._key)}
          className={cn(
            'bg-bor-primary-400 relative h-[72px] w-[72px] overflow-hidden rounded opacity-25 [&>span]:!h-full [&>span]:!w-full',
            (item.slide as IVisualAsset).youtubeVideoId && '[&_.ytp-large-play-button]:hidden',
            i === thumbnails.activeIndex && 'h-20 w-20 opacity-100',
            i !== thumbnails.activeIndex && 'cursor-pointer hover:opacity-50'
          )}
        >
          {(item.slide as IVisualAsset).image ? (
            <NextImage
              objectFit='cover'
              image={(item.slide as IVisualAsset).image}
              alt=''
              className='rounded'
            />
          ) : item.slide._type === 'image' && 'asset' in item.slide ? (
            <NextImage objectFit='cover' image={item.slide} alt='' className='rounded' />
          ) : (item.slide as IVisualAsset).youtubeVideoId ? (
            <VideoThumbnail
              src={
                (item.slide as IVisualAsset).youtubeVideoData?.thumbnailUrl[1] ||
                `https://img.youtube.com/vi/${(item.slide as IVisualAsset).youtubeVideoId}/0.jpg`
              }
            />
          ) : item.slide.mediaType === 'wistiaVideo' ? (
            <VideoThumbnail src={item.slide.wistiaVideoData?.thumbnailUrl[0]} />
          ) : item.slide.mediaType === 'inlineVideo' ? (
            <VideoThumbnail image={item.slide.inlineVideoThumbnail} />
          ) : (
            <Box className='bg-bor-primary-400 h-full' />
          )}
        </Box>
      ))}
    </Box>
  );
};

const VideoThumbnail: React.FC<{ src?: string; image?: SanityImage }> = ({ src, image }) => {
  if (src) {
    return (
      <Box className='relative h-full w-full'>
        <Play
          className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform'
          size={24}
          weight='fill'
          color='#FAFAFA'
        />
        <Image className='!h-full object-cover' src={src} width='80' height='80' alt='' />
      </Box>
    );
  }

  if (image) {
    return (
      <Box className='relative h-full w-full [&_>_span]:!h-full'>
        <Play
          className='absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform'
          size={24}
          weight='fill'
          color='#FAFAFA'
        />
        <NextImage
          objectFit='cover'
          image={image}
          alt=''
          className='!h-full rounded object-cover'
        />
      </Box>
    );
  }

  return (
    <Box className='relative h-full w-full'>
      <Play
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform'
        size={24}
        weight='fill'
        color='#FAFAFA'
      />
      <Box className='bg-bor-primary-400 h-full' />
    </Box>
  );
};
