import React from 'react';
import { useControls } from 'react-zoom-pan-pinch';
import { cn } from '@superside/ui';
import { Box, Button, Text } from '@konsus/superside-kit';
import styles from './ZoomInAndOut.module.css';

export type ZoomInAndOutPropsType = {
  setZoom: (percentage: any) => void;
  percentage: number;
};

const ZOOM_STEP = 0.25;

export const ZoomInAndOut: React.FC<ZoomInAndOutPropsType> = ({ setZoom, percentage }) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  const onZoomInClick = () => {
    if (percentage < 250) {
      zoomIn(ZOOM_STEP);
      setZoom((prev: number) => prev + 25);
    }
  };

  const onZoomOutClick = () => {
    if (percentage > 100) {
      if (percentage === 125) {
        resetTransform();
      }
      zoomOut(ZOOM_STEP, 100);
      setZoom((prev: number) => prev - 25);
    }
  };

  return (
    <Box
      className={
        '<768:hidden border-light/15 h-10 flex-row items-center justify-center gap-6 rounded border-[1.5px] border-solid p-1'
      }
    >
      <Button
        onClick={onZoomOutClick}
        className={cn(
          `${styles.unstyledBtnStyle} text-white-700 m-0 flex select-none border-[none] p-0`,
          percentage === 100 && 'cursor-default opacity-50'
        )}
      >
        {' '}
        <MinusSign />{' '}
      </Button>
      <Text className={'text-white-700 select-none text-base font-semibold'}>{percentage}%</Text>
      <Button
        onClick={onZoomInClick}
        className={cn(
          `${styles.unstyledBtnStyle} text-white-700 m-0 flex select-none border-[none] p-0`,
          percentage === 250 && 'cursor-default opacity-50'
        )}
      >
        {' '}
        <PlusSign />{' '}
      </Button>
    </Box>
  );
};

export const PlusSign = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='16.125'
        y1='21.125'
        x2='16.125'
        y2='10.875'
        stroke='#FAFAFA'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
      <line
        x1='10.875'
        y1='16.125'
        x2='21.125'
        y2='16.125'
        stroke='#FAFAFA'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
    </svg>
  );
};

export const MinusSign = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='10.875'
        y1='15.525'
        x2='21.125'
        y2='15.525'
        stroke='#FAFAFA'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
    </svg>
  );
};
