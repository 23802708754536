import { useMemo, useCallback } from 'react';

export const useSlider = (currentIndex: number, setCurrentIndex, slides: any[] = []) => {
  const nextSlide = useCallback(() => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, slides.length, setCurrentIndex]);

  const prevSlide = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, setCurrentIndex]);

  const thumbnailSlides = useMemo(() => {
    if (!slides || slides.length === 0) {
      return [];
    }

    const start = Math.max(0, Math.min(slides.length - 5, currentIndex - 2));
    const end = start + 5;

    return slides.slice(start, end);
  }, [slides, currentIndex]);

  const activeIndex = useMemo(() => {
    if (currentIndex < 2) {
      return currentIndex;
    } else if (currentIndex >= slides.length - 2) {
      return 5 - (slides.length - currentIndex);
    } else {
      return 2;
    }
  }, [currentIndex, slides.length]);

  return {
    nextSlide,
    prevSlide,
    thumbnails: {
      slides: thumbnailSlides,
      activeIndex
    }
  };
};
