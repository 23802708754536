import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ShareNetwork, X } from 'phosphor-react';
import {
  TransformWrapper,
  TransformComponent,
  type ReactZoomPanPinchHandlers
} from 'react-zoom-pan-pinch';
import { cn } from '@superside/ui';
import NextImage from '@konsus/image';
import {
  Box,
  Modal,
  RelativeBox,
  previewMediaValueAtom,
  useResponsiveContext
} from '@konsus/superside-kit';
import { useAtomMutator, useAtomValue } from '@konsus/atoms';
import { VisualAsset } from '@konsus/lego/src/components/VisualAsset';
import type { IVisualAsset } from '@konsus/lego/src/components/VisualAsset/types';
import type { CtaProps } from '@konsus/lego/src/types/ctaButton';
import { ProgressiveModalEnabledCta } from '@konsus/lego/src/components/HeroCtaGroup';
import styles from './index.module.css';
import { useSlider } from './useSlider';
import { SlidesNavigation } from './SlidesNavigation';
import { PreviewModalHeader } from './PreviewModalHeader';
import { Thumbnails } from './Thumbnails';

const ShareButtons = dynamic(
  () =>
    // eslint-disable-next-line
    import(
      /* webpackChunkName: "Lego_Desktop_ShareButtons" */ '@konsus/lego/src/blocks/ShareButtons'
    )
);

const MobileSlider = dynamic(() => import(/* webpackChunkName: "MobileSlider" */ './MobileSlider'));

type NavigationHandler = (
  e: React.MouseEvent | React.PointerEvent,
  resetTransform: () => void
) => void;

export interface SlidesNavigationProps {
  currentIndex: number;
  totalSlides: number;
  onPrev: NavigationHandler;
  onNext: NavigationHandler;
  resetTransform: ReactZoomPanPinchHandlers['resetTransform'];
}

export const MediaPreviewModal: React.FC<{ title?: string; ctaButton?: CtaProps }> = ({
  title,
  ctaButton
}) => {
  const { asPath } = useRouter();
  const {
    image,
    visualAsset,
    slides,
    youtubeVideo,
    wistiaVideo,
    inlineVideo,
    alt,
    title: customTitle,
    hashtags,
    cta = ctaButton,
    url = `https://www.superside.com${asPath}`,
    activeIndex = 0
  } = useAtomValue(previewMediaValueAtom);

  const setPreviewMediaValue = useAtomMutator(previewMediaValueAtom);
  const screenSize = useResponsiveContext();

  const { push } = useRouter();
  const isMobile =
    screenSize === 'small' ||
    screenSize === 'xsmall' ||
    screenSize === 'medium' ||
    screenSize === 'large';
  const CLICK_TO_ZOOM_VALUE = 1.05;

  const [zoom, setZoom] = useState(100);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isClick, setIsClick] = useState(true);
  const [isOpenShareButton, setIsOpenShareButton] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  useEffect(() => {
    setCurrentIndex(activeIndex);
  }, [activeIndex]);

  const { nextSlide, prevSlide, thumbnails } = useSlider(currentIndex, setCurrentIndex, slides);

  const isVisualAssetImage = useMemo(() => {
    if (visualAsset) {
      return Boolean(visualAsset && visualAsset.mediaType === 'image');
    }

    if (slides?.length) {
      return ['image', 'visualAsset'].includes(slides[currentIndex].slide._type as string);
    }
  }, [visualAsset, slides, currentIndex]);

  const withMobileImage = useMemo(() => {
    if (isVisualAssetImage) {
      return Boolean(visualAsset?.imageMobile);
    }

    if (slides?.length) {
      const currentSlide = slides[currentIndex].slide;

      return 'imageMobile' in currentSlide ? Boolean(currentSlide.imageMobile) : false;
    }
  }, [currentIndex, isVisualAssetImage, slides, visualAsset]);

  const closeButtonHandler = () => {
    setPreviewMediaValue({ image: undefined });
    setZoom(100);

    if (mediaValue) {
      push({ query: {} }, undefined, { shallow: true });
    }
  };

  const isZoomedIn = useMemo(() => {
    return zoom > 100;
  }, [zoom]);

  if (!image && !visualAsset && !youtubeVideo && !wistiaVideo && !inlineVideo && !slides?.length) {
    return null;
  }

  const mediaValue = youtubeVideo || wistiaVideo || inlineVideo;

  const handlePrevSlide = (
    e: React.MouseEvent | React.PointerEvent,
    resetTransform: () => void
  ) => {
    e.stopPropagation();
    resetTransform();
    setZoom(100);
    prevSlide();
  };

  const handleNextSlide = (
    e: React.MouseEvent | React.PointerEvent,
    resetTransform: () => void
  ) => {
    e.stopPropagation();
    resetTransform();
    setZoom(100);
    nextSlide();
  };

  const handlePointerDown = () => {
    setIsClick(true);
  };

  const handlePointerMove = () => {
    setIsClick(false);
    handleMouseMove();
  };

  const handlePointerUp = (event: React.PointerEvent) => {
    if (isClick) {
      handleMouseUp(event);
    }
    setIsClick(true);
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (isDragging) {
      e.stopPropagation();
      setIsDragging(false);
    }
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleBoxZoomClick = (
    zoomIn: ReactZoomPanPinchHandlers['zoomIn'],
    resetTransform: ReactZoomPanPinchHandlers['resetTransform']
  ) => {
    if (isDragging || isMobile || mediaValue) {
      return;
    }
    if (isZoomedIn) {
      resetTransform();
      setZoom(100);
    } else {
      zoomIn(CLICK_TO_ZOOM_VALUE);
      setZoom(250);
    }
  };

  const transformComponentStyles =
    image || isVisualAssetImage || slides?.length
      ? {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      : {};

  return (
    <Modal
      full
      fill
      disableHeader
      pad='none'
      width='full'
      responsive={false}
      background='rgba(11, 25, 38, 0.9)'
      close={closeButtonHandler}
    >
      <RelativeBox className={'h-[100dvh] max-h-screen items-center justify-center'}>
        <TransformWrapper
          wheel={{ disabled: true }}
          doubleClick={{ disabled: !isMobile, step: 2, animationTime: 100 }}
          pinch={{ disabled: !isMobile }}
          initialScale={1}
          maxScale={2.5}
          onZoomStart={() => setZoom((prev) => prev + 100)}
          centerOnInit
          panning={{
            lockAxisX: !isZoomedIn,
            lockAxisY: !isZoomedIn
          }}
          disabled={Boolean(slides?.length && isMobile)}
        >
          {({ zoomIn, resetTransform }) => (
            <React.Fragment>
              <PreviewModalHeader
                title={title}
                customTitle={customTitle}
                hashtags={slides ? slides[currentIndex].hashtags || [] : hashtags}
                closeButtonHandler={closeButtonHandler}
                image={image}
                isVisualAssetImage={isVisualAssetImage as boolean}
                zoom={zoom}
                setZoom={setZoom}
                resetTransform={resetTransform}
                nextSlide={nextSlide}
                prevSlide={prevSlide}
              />

              <RelativeBox
                id='RelativeBox'
                className={cn(
                  '1280:px-[72px] 1280:py-10 768:p-6 1280:cursor-zoom-in relative z-[2] h-full max-h-[80vh] w-full justify-center p-4',
                  (youtubeVideo || wistiaVideo || inlineVideo) &&
                    '1024:max-w-[1100px] 1650:max-w-[1410px] max-w-screen-md cursor-default',
                  isZoomedIn && 'cursor-zoom-out p-0',
                  image && isMobile && 'p-0'
                )}
                onClick={() => handleBoxZoomClick(zoomIn, resetTransform)}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
              >
                {slides?.length ? (
                  <SlidesNavigation
                    onPrev={handlePrevSlide}
                    onNext={handleNextSlide}
                    currentIndex={currentIndex}
                    totalSlides={slides.length}
                    resetTransform={resetTransform}
                  />
                ) : null}

                <TransformComponent
                  contentStyle={transformComponentStyles}
                  wrapperStyle={transformComponentStyles}
                  contentClass={cn(
                    image && '[&>span]:!h-full [&>span]:!w-full',
                    isVisualAssetImage &&
                      '[&>div>span]:!h-full [&>div>span]:!w-full [&>div]:!h-full [&>div]:!w-full',
                    withMobileImage &&
                      '[&>div]:!h-full [&>div]:!w-full [&>div]:justify-center [&>div>div>span]:!h-full [&>div>div>span]:!w-full',
                    slides &&
                      ['inlineVideo', 'wistiaVideo', 'youtubeVideo'].includes(
                        (slides[currentIndex].slide as IVisualAsset).mediaType
                      ) &&
                      '<768:[&_.swiper-slide>span]:w-full <768:[&_.swiper-slide>span]:aspect-[1.77] max-w-[1900px] self-center [&>span]:h-full [&>span]:max-h-[940px] [&>span]:w-auto [&_iframe]:h-full [&_iframe]:w-auto',
                    slides &&
                      (slides[currentIndex].slide as IVisualAsset).mediaType === 'wistiaVideo' &&
                      'first:[&>span]:bg-grey-500/10 first:[&>span]:flex first:[&>span]:!w-full first:[&>span]:items-center',
                    '[&_p]:hidden'
                  )}
                >
                  {slides?.length ? (
                    isMobile ? (
                      <MobileSlider slides={slides} setCurrentIndex={setCurrentIndex} />
                    ) : slides[currentIndex].slide._type === 'image' ? (
                      <VisualAsset
                        mediaType='image'
                        image={slides[currentIndex].slide}
                        layout='responsive'
                        objectFit='contain'
                      />
                    ) : (
                      <VisualAsset
                        {...slides[currentIndex].slide}
                        layout='responsive'
                        objectFit='contain'
                      />
                    )
                  ) : null}

                  {isVisualAssetImage && !slides?.length ? (
                    <VisualAsset {...visualAsset} layout='responsive' objectFit='contain' />
                  ) : null}

                  {image && !isVisualAssetImage && !slides?.length ? (
                    <NextImage layout='responsive' objectFit='contain' image={image} alt={alt!} />
                  ) : null}
                </TransformComponent>

                {mediaValue ? (
                  <VisualAsset mediaType={mediaValue.mediaType} {...mediaValue} />
                ) : null}
              </RelativeBox>
              <Box
                className={cn(
                  styles.modalFooter,
                  '1280:grid 1280:grid-cols-[1fr_min-content_1fr] absolute bottom-0 left-0 z-[3] w-full flex-row items-center justify-between p-6'
                )}
              >
                {slides?.length ? (
                  <Thumbnails
                    slides={slides}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    thumbnails={thumbnails}
                    setZoom={setZoom}
                    resetTransform={resetTransform}
                  />
                ) : (
                  <Box />
                )}

                {slides?.length ? (
                  <Box className='1280:w-[100px] 1280:items-center'>
                    <span className='text-light'>
                      {currentIndex + 1} of {slides.length}
                    </span>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box className='1024:gap-10 dark flex-row items-center gap-4 justify-self-end'>
                  {cta ? (
                    <ProgressiveModalEnabledCta
                      className='py-2 px-8'
                      handleClick={closeButtonHandler}
                      cta={cta}
                    />
                  ) : null}

                  <Box className='relative'>
                    <ShareButtons
                      className={cn(
                        !isOpenShareButton && 'hidden',
                        '1024:static 1024:flex 1024:flex-row absolute left-0 bottom-[52px] flex-col'
                      )}
                      shareUrl={url}
                      includeCopyLink
                      size='small'
                      variant='light'
                    />
                    <Box
                      role='button'
                      onClick={() => setIsOpenShareButton((prev) => !prev)}
                      className='1024:hidden cursor-pointer rounded-full bg-[#FAFAFA] p-2.5'
                    >
                      {isOpenShareButton ? <X size={20} /> : <ShareNetwork size={20} />}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </TransformWrapper>
      </RelativeBox>
    </Modal>
  );
};

export default MediaPreviewModal;
